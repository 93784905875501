<template>
<div class="loading-wrapper">
  <img src="@/assets/images/loading.png">
</div>
</template>

<style scoped>
.loading-wrapper{
  position: fixed;
  z-index: 999;
  left: 50%;
  top: 50%;
  margin-left: -2rem;
  margin-top: -2rem;
  width: 4rem;
  height: 4rem;
  background-color: rgba(0,0,0,.3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.loading-wrapper img{
  width: 50%;
  height: 50%;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from{
    transform: rotate(0);
  }
  to{
    transform: rotate(360deg);
  }
}
</style>