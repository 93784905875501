let device = navigator.userAgent.toLowerCase();
export function _isMobile() {
  if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
    return 1
  }
  return 0
}

export function _isIOS(params) {
  return /iphone|ipod|iOS/.test(device)
}

export function _getUrlParams(key) {
  let str = window.location.search
  if (!str) return
  str = str.indexOf('?') != -1 ? str.split('?')[1].split('&').map(v => v.split('=')).reduce((pre, curr) => {
    pre[curr[0]] = curr[1]
    return pre
  }, {}) : ''
  return str[key]
}

export function _get(params, callback) {
  let token
  token = localStorage.getItem('tk')
  if (!token) {
    token = buildUUID()
    localStorage.setItem('tk', token)
  }


  let xhr = new XMLHttpRequest()
  let p = '?'
  for (let key in params) {
    p += `${key}=${params[key]}&`
  }
  p = p.slice(0, -1)

  xhr.open('get', 'https://api.metaverse.avr-travel.com/behavior/record' + p)
  xhr.setRequestHeader("token", token);

  let hasResponse = false
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4) {
      hasResponse = true
      callback && callback()
    }
  }
  setTimeout(() => {
    if (!hasResponse) {
      callback && callback()
    }
  }, 1000);

  xhr.send()
}

const hexList = [];
for (let i = 0; i <= 15; i++) {
  hexList[i] = i.toString(16);
}

function buildUUID() {
  let uuid = "";
  for (let i = 1; i <= 36; i++) {
    if (i === 9 || i === 14 || i === 19 || i === 24) {
      uuid += "-";
    } else if (i === 15) {
      uuid += 4;
    } else if (i === 20) {
      uuid += hexList[(Math.random() * 4) | 8];
    } else {
      uuid += hexList[(Math.random() * 16) | 0];
    }
  }
  return uuid.replace(/-/g, "");
}