<template>
  <div class="w100 h100 start-container">
    <div class="start-logo">
      <img :src="imgLoading" />
    </div>
    <h5 class="error-msg">{{ codeDict[messageCode] || codeDict[999] }}</h5>
    <div class="enter-button" v-if="showEnter" @click="handleEnter">
      <h3 style="font-size: 14px; margin-right: 4px">进入应用</h3>
      <img
        src="data:image/svg+xml,%3csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3e %3cpath d='M12.1152 18.1756L4.00095 25.3513L4.00095 11L12.1152 18.1756Z' fill='white' /%3e %3cpath d='M23.5723 18.1755L16.1151 23.9755L16.1151 12.3755L23.5723 18.1755Z' fill='white' fill-opacity='0.6' /%3e %3cpath d='M32.373 18.1756L26.573 22.3184L26.573 14.0327L32.373 18.1756Z' fill='white' fill-opacity='0.29' /%3e%3c/svg%3e"
        style="width: 18px"
      />
    </div>
  </div>
</template>

<script>
import { _getUrlParams } from "@/assets/utils";

const codeDict = {
  0: "可视化服务启动中...",
  1: "可视化服务连接中...",
  2: "连接成功，资源加载中...",
  3: "资源加载完成",
  4: "正在进入应用...",
  999: "服务连接失败，请刷新页面或稍后重试",
  100: "失败",
  301: "需要跳转地址",
  403: "没有权限",
  404: "平台暂无可用资源，请稍后再试",
  500: "服务器处理失败",
  600: "非法参数",
  601: "缺少必要参数",
  602: "需要用户登录",
  603: "非法请求",
  610: "签名过期",
  611: "非法签名",
  612: "非法鉴权",
  700: "已经到底啦",
  701: "已存在相同记录",
  705: "该链接已不存在",
  720: "没有上架权限",
  800: "用户名已存在",
  801: "手机号已存在",
  802: "验证码不正确，请重新输入",
  803: "账号或密钥输入不正确",
  804: "需要图形验证码验证",
  805: "您还剩余三次机会",
  806: "您还剩余二次机会",
  807: "您只剩余一次机会，若忘记密钥，可尝试找回密钥",
  808: "智能验证失败",
  809: "如有疑问，请拨打电话 18588403835，或者发送邮件至 yanjunyun@rayvision.com。",
  810: "验证码发送次数过多，请明天再试",
  811: "验证码不正确，请重新输入",
  812: "该手机号未绑定账户",
  813: "旧密钥不正确，请重新输入",
  1000: "访问密钥错误",
  1001: "链接已过期",
  1002: "存在相同的应用程序包",
  1003: "应用已下架",
  1004: "应用已删除",
  1005: "链接未生效",
  1018: "资源不足",
  1019: "当前资源已被占满，请稍后再试",
  1101: "token 不存在",
  1102: "投屏端数量已到达上限",
  1103: "昵称已存在",
  2100: "余额不足，请充值后再打开应用",
  3001: "投屏正在运行",
  3002: "主控端未开启",
  3003: "口令错误",
  3004: "当前投屏端进入人数已满",
  3005: "该口令已被创建使用",
  3006: "暂不支持 sdk 访问投屏链接",
};
export default {
  props: {
    messageCode: {
      type: String,
      default: "",
    },
    showEnter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props,ctx) {
    let imgLoading = require("@/assets/logo.png");
    if (_getUrlParams("target") === "2") {
      imgLoading = require("@/assets/logo2.png");
      document.title = "广府庙会";
    } else {
      document.title = "广州非遗街区（北京路）";
    }

    // fetch(
    //   "http://api-auth.avr-travel.com/api/v1/wx/config?url=https://gfwhz.avr-travel.com/"
    // )
    //   .then((respone) => respone.json())
    //   .then((res) => {
    //     console.log(res);
    //     const data = res.data;
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
    //       appId: data.appId, // 必填，公众号的唯一标识
    //       timestamp: data.timeStamp, // 必填，生成签名的时间戳
    //       nonceStr: data.nonceStr, // 必填，生成签名的随机串
    //       signature: data.signature, // 必填，签名
    //       jsApiList: [], // 必填，需要使用的JS接口列表
    //       openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
    //     });
    //   });

    const handleEnter = () => {
      ctx.emit("do-enter");
    };
    return {
      codeDict,
      imgLoading,
      handleEnter,
    };
  },
};
</script>


<style scoped>
.start-container {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9;
  flex-direction: column;
  background-image: var(--page-gradient);
}
.error-msg {
  color: var(--text-color);
  margin-top: 30px;
}
.start-logo {
  height: 34%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.start-logo img {
  height: 100%;
  animation: shine 2s linear infinite;
  user-select: none;
}

@keyframes shine {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
}
.enter-button {
  height: 50px;
  width: 14%;
  margin: 0 auto;
  padding: 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  cursor: pointer;
  color: white;
  position: relative;
  margin-top: 30px;
  background-color: #ebd39c;
}
</style>