<template>
  <transition name="modal">
    <div class="win-modal-wrapper" v-show="visible">
      <a class="win-modal-close-btn" @click="hide">×</a>
      <div class="win-modal-content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup(prop, ctx) {
    let visible = ref(false);

    const show = () => {
      visible.value = true;
    };
    const hide = () => {
      visible.value = false;
      ctx.emit("close");
    };
    return {
      visible,
      show,
      hide,
    };
  },
});
</script>


<style>
@media screen and (orientation: portrait) {
  .win-modal-wrapper {
    width: 80%;
    height: 80vh;
  }
  .win-modal-content .slot-content {
    width: 100%;
    margin: auto;
    display: block;
  }
}
@media screen and (orientation: landscape) {
  .win-modal-wrapper {
    width: 60%;
    height: 70vh;
  }
  .win-modal-content .slot-content {
    height: 100%;
    margin: auto;
    display: block;
  }
}
.win-modal-wrapper {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background: linear-gradient(to bottom, #f9c983, #edab4a);
  padding: 0.8rem;
  border-radius: 1.4rem;
  box-shadow: 0 10px 14px -7px #000;
}
.win-modal-content {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: #fbead1;
  box-shadow: 0 2px 8px rgb(0, 0, 0, 0.2);
  overflow: hidden;
}

.win-modal-close-btn {
  position: absolute;
  bottom: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.7rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  user-select: none;
}
.win-modal-close-btn:hover,
.win-modal-close-btn:active {
  background-color: rgba(255, 255, 255, 0.5);
}

.win-modal-enter-active {
  transform-origin: center;
  animation: bounce 0.2s linear;
  transform-origin: left;
}
.win-modal-leave-active {
  transform-origin: center;
  animation: bounce 0.2s linear reverse;
  transform-origin: left;
}
@keyframes bounce {
  0% {
    transform: scale(0) translateX(-50%);
  }
  80% {
    transform: scale(1.2) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}
</style>