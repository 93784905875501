<template>
  <div class="setting-wrapper">
    <a class="setting-btn"><span>设置</span></a>
  </div>
</template>

<style scoped>
.setting-wrapper {
  position: fixed;
  left: 2rem;
  top: 2rem;
  color: var(--text-color);
  font-size: 0.8rem;
  z-index: 999;
}
.setting-btn {
  display: block;
  width: 38px;
  height: 38px;
  text-align: center;
  background-image: url("../assets/images/set-back.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>