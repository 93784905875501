<template>
  <home-view />
</template>

<script>
import HomeView from "@/components/HomeView.vue";
export default {
  name: "App",
  components: {
    HomeView,
  },
};
</script>

<style>
#app {
  height: 100vh;
  background-color: var(--primary-color);
}
video{
  user-select: none;
  border: none;
  outline: none;
  outline-style: none;
}
video:focus-visible,video:active,video:focus{
  outline: -webkit-focus-ring-color auto 0px; 
}
</style>
