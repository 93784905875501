<template>
  <div class="container">
    <start-page
      v-if="data.showStart"
      :messageCode="data.msgCode"
      :showEnter="data.showEnter"
      @do-enter="doEnter"
    />
    <div id="mainWrapper" class="wrapper-notfullscreen">
      <!-- <div class="meta-btn">
        <img src="@/assets/images/tometa.png" alt="" />
      </div> -->
      <rocking-bar
        v-if="data.showRocker"
        @send="handleSend"
        v-for="item in rockList"
        :msgCode="data.msgCode"
        :config="item"
      />
      <a
        v-if="!data.showStart && data.showZoom"
        class="float-btn"
        @click="toggle"
      >
        <img
          v-show="!data.isFullScreen"
          src="@/assets/images/zoom-in.png"
          alt=""
        />
        <img
          v-show="data.isFullScreen"
          src="@/assets/images/zoom-out.png"
          alt=""
        />
      </a>

      <modal ref="codeModal">
        <div style="height: calc(100% - 2rem)">
          <img class="slot-content" :src="QR_CODE[data.codeIdx]" alt="" />
        </div>
      </modal>
      <win-modal ref="addrModal" @close="data.address = ''">
        <iframe width="100%" height="100%" :src="data.address"></iframe>
      </win-modal>
      <loading v-show="data.isLoading" />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { Launcher } from "live-cat";

import { LauncherUI } from "live-cat-library";
import StartPage from "./StartPage.vue";
import RockingBar from "./RockingBar.vue";
import Modal from "./Modal.vue";
import WinModal from "./WinModal.vue";
import Loading from "./Loading.vue";
import Setting from "./Setting.vue";
import { _isMobile, _isIOS, _getUrlParams, _get } from "@/assets/utils";

const appKey = "DbhqWadBbpYO0nAz";
// const appKey = "i2avK6vlFxQc4ZN6";
const address = "https://app.3dcat.live";

export default {
  name: "HomeView",
  components: { StartPage, RockingBar, Modal, Loading, Setting, WinModal },
  setup() {
    const data = reactive({
      showStart: true,
      showEnter: false, //启动页显示(IOS系统浏览器机制问题，需要用户手动点击才能自动播放)
      isLoading: false,
      msgCode: "0",
      isFullScreen: false,
      showRocker: false,
      showZoom: false,
      codeIdx: 1,
      address: "",
    });
    const QR_CODE = {
      0: require("@/assets/images/qrcode.jpg"),
      1: require("@/assets/qrcode/1.jpg"),
      2: require("@/assets/qrcode/2.jpg"),
      3: require("@/assets/qrcode/3.jpg"),
      4: require("@/assets/qrcode/4.jpg"),
      5: require("@/assets/qrcode/5.jpg"),
      6: require("@/assets/qrcode/6.jpg"),
      7: require("@/assets/qrcode/7.jpg"),
      8: require("@/assets/qrcode/8.jpg"),
      9: require("@/assets/qrcode/9.jpg"),
      10: require("@/assets/qrcode/10.jpg"),
    };

    //埋点
    _get({ app_id: 4, type: 1 });

    let launcher, player, connection, wrapper;
    const leftRocker = ref(null);
    const rightRocker = ref(null);
    const rockList = [
      {
        name: "leftRocker",
        mode: "static",
        position: { left: "20%", bottom: "20%" },
        color: "white",
        ref: leftRocker,
        joystick: 0,
      },
      {
        name: "rightRocker",
        mode: "static",
        position: { right: "20%", bottom: "20%" },
        color: "white",
        ref: rightRocker,
        joystick: 1,
      },
    ];

    const init = async () => {
      wrapper = document.querySelector("#mainWrapper");
      //初始化
      try {
        //公有化
        data.msgCode = "1";
        // launcher = new Launcher({
        //   baseOptions: {
        //     address,
        //     appKey,
        //   },
        //   extendOptions: {
        //     onPlay: () => {
        //       //加载成功
        //       data.msgCode = "3";
        //       //启动页隐藏
        //       data.showStart = false;
        //       //接收指令信息
        //       initMsgReceive();
        //       sendTargetMsg();
        //       if (_getUrlParams("target") === "2") {
        //         document.title = "广府庙会";
        //       } else {
        //         document.title = "广州非遗街区（北京路）";
        //       }
        //     },
        //   },
        // });
        launcher = new LauncherUI(
          {
            address,
            appKey,
            startType: 1,
          },
          wrapper,
          {
            startBitrate: 15000,
            minBitrate: 8000,
            maxBitrate: 15000,
            onPlay: () => {
              // player = launcher.launcherBase.player;
              data.msgCode = "3";
              //启动页隐藏
              data.showStart = false;
              //接收指令信息
              sendTargetMsg();
              if (_getUrlParams("target") === "2") {
                document.title = "广府庙会";
              } else {
                document.title = "广州非遗街区（北京路）";
              }
            },
            onPhaseChange: (phase, deltaTime) => {
              console.log(phase);
              if (phase === "signaling-connected") {
                initMsgReceive();
                data.msgCode = "1";
              }
              if (phase === "data-channel-open") {
                data.msgCode = "2";
                if (_isIOS()) {
                  data.showEnter = true;
                }
              }
              if (phase === "streaming-playing") {
              }
            },
            onError: (err) => {
              console.log(err);
            },
          }
        );

        // await launcher.automata(wrapper);
        // player = launcher.getPlayer();

        // connection = launcher.getConnection();
        // connection.event.connect.on((msg) => {
        //   data.msgCode = "1";
        // });
        // connection.event.dataChannelConnected.on((msg) => {
        //   data.msgCode = "2";
        //   if (launcher.getManualPlay()) {
        //     manualPlay = launcher.getManualPlay();
        //   }
        //   data.showEnter = _isIOS();
        //   console.log("连接成功，资源加载中...");
        // });

        // console.log(launcher);
      } catch (error) {
        console.log(error); //错误代码
        data.msgCode = error;
      }
    };

    //接收信息
    const initMsgReceive = () => {
      connection = launcher.launcherBase.connection;
      connection.event.interaction.on((res) => {
        console.log(res);
        try {
          let d = JSON.parse(res);
          console.log(d);

          switch (d.reciveMsg) {
            case 0:
              if (d.operation === 0) {
                codeModal.value.show();
                let goods = d.goods;
                data.codeIdx = goods.id;
              } else {
                data.isLoading = true;
                setTimeout(() => {
                  data.isLoading = false;
                  console.log(_isMobile());
                  if (_isMobile()) {
                    //移动端使用iframe
                    data.address = d.goods.url;
                    addrModal.value.show();
                  } else {
                    //pc端直接打开外链
                    window.open(d.goods.url);
                  }
                }, 1000);
              }
              break;

            case 1:
              let command = JSON.stringify({ sendMsg: 1, device: _isMobile() });
              handleSend(command);
              break;

            case 2:
              window.location.href = `https://yxtemplefair.avr-travel.com?_t=${new Date().getTime()}`;
              break;
          }
        } catch (error) {
          console.log(error);
        }
      });
    };

    onMounted(() => {
      if (_isMobile()) {
        data.showRocker = true;
      } else {
        data.showRocker = false;
      }
      if (_isIOS()) {
        data.showZoom = false;
      } else {
        data.showZoom = true;
      }
      //挂载
      // init();
      if (
        navigator.userAgent.includes("miniProgram") ||
        navigator.userAgent.includes("MicroMessenger")
      ) {
        //微信浏览器/微信小程序环境
        document.addEventListener("WeixinJSBridgeReady", init, false);
      } else {
        init();
      }
    });

    const toggle = () => {
      data.isFullScreen = !data.isFullScreen;
      launcher.toggleFullscreen();
    };

    const doEnter = () => {
      console.log("enter");
      data.showEnter = false;
      // data.msgCode = "4"
      launcher.launcherBase.resumeVideoStream();
    };

    const handleSend = (e) => {
      console.log(e);
      connection.emitUIInteraction(e); //发送指令
      console.log("指令发送");
    };

    const codeModal = ref(null);
    const addrModal = ref(null);

    //发送非遗街区字段
    const sendTargetMsg = () => {
      let target = _getUrlParams("target");
      if (target) {
        let msg = { sendMsg: 4, target: parseInt(target) };
        handleSend(JSON.stringify(msg));
      }
    };

    return {
      data,
      toggle,
      rockList,
      handleSend,
      codeModal,
      addrModal,
      QR_CODE,
      doEnter,
    };
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  width: 100%;
}
.meta-btn {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: 999;
  width: 10%;
  height: 4%;
}
.meta-btn img {
  width: 100%;
  height: 100%;
}
.float-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 999;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(60, 60, 60, 0.5);
  padding: 4px;
  /* border: 2px solid #fff; */
}
.float-btn:active {
  background-color: rgba(255, 255, 255, 0.5);
}
.float-btn img {
  width: 100%;
  height: 100%;
}

.wrapper-notfullscreen {
  height: 100vh;
  width: 100vw;
  position: fixed;
  /* top: 50%; */
  left: 0;
  /* transform: translateY(-50%) !important; */
}
</style>