<template>
  <div class="rocker-cls" ref="rocker"></div>
</template>

<script>
import { ref, watch } from "vue";
import nipplejs from "nipplejs";

export default {
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    msgCode: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const data = {
      dataObj: {
        sendMsg: 0,
        joystick: 0,
        touchPoint: {},
      },
    };
    const iconf = props.config;
    const rocker = ref(null);
    watch(
      () => props.msgCode,
      (nv) => {
        console.log(nv, "变化");
        if (nv === "3") {
          //初始化摇杆
          initRocker();
        }
      }
    );
    const initRocker = () => {
      console.log(rocker);
      data[iconf.name] = nipplejs.create({
        zone: rocker.value,
        ...iconf,
      });
      data[iconf.name]
        .on("start", () => {
          data.operaterCMD = iconf.name;
          data[iconf.name].active = true;
        })
        .on("move", onMove)
        .on("end", () => {
          data[iconf.name].active = false;
          console.log("摇杆结束");

          //循环发送重置
          data.dataObj.touchPoint = { x: 0, y: 0 };
          for (let i=0;i<5;i++) {
            setTimeout(() => {
              send();
            }, 300 * i);
          }
        });
    };

    //摇杆移动
    const onMove = (e, d) => {
      rockerKeepPress();
      const X = +d.vector.x.toFixed(2);
      const Y = +d.vector.y.toFixed(2);
      if (iconf.joystick === 0) {
        data.dataObj.touchPoint = { x: Y, y: X };
        console.log({ x: X, y: Y });
      } else {
        data.dataObj.touchPoint = { x: X, y: Y };
      }
      // if (iconf.joystick === 0) {
      //   data.dataObj.touchPoint = { x: X, y: -Y };
      //   console.log({ x: X, y: Y });
      // } else {
      //   data.dataObj.touchPoint = { x: -Y, y: X };
      // }
      data.dataObj.joystick = iconf.joystick;
      send();
    };

    let intervalTime = 100;
    let onPressTimer = null;
    //摇杆持续按住
    const rockerKeepPress = () => {
      if (onPressTimer) {
        clearInterval(onPressTimer);
      }
      onPressTimer = setInterval(() => {
        if (data[data.operaterCMD].active) {
          send();
        } else {
          clearInterval(onPressTimer);
          onPressTimer = null;
        }
      }, intervalTime);
    };

    const send = () => {
      let command = JSON.stringify(data.dataObj);
      // console.log(command);
      ctx.emit("send", command); //发送指令
    };

    return { rocker };
  },
};
</script>

<style scoped>
.rocking-wrapper {
  position: absolute;
  z-index: 9;
  left: 6vw;
  bottom: 6vw;
  width: 15vw;
  height: 15vw;
  background-color: rgba(203, 208, 220, 0.6);
  border-radius: 50%;
  /* padding: 6px; */
}
.rocking-box {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  border: 2px solid #fff;
}
.rocking-item {
  height: 40%;
  width: 40%;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  /* transform: translate(-50%, -50%); */
}
.rocker-cls{
  /* transform: rotate(90deg); */
}
</style>