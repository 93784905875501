<template>
  <transition name="modal">
    <div class="modal-wrapper" v-show="visible">
      <a class="modal-close-btn" @click="visible = false">×</a>
      <div class="modal-content">
        <slot></slot>
        <div class="modal-footer-text">
          <p>请长按识别/保存二维码</p>
          <p>了解商品更多详细内容</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    let visible = ref(false);

    const show = () => {
      visible.value = true;
    };
    return {
      visible,
      show,
    };
  },
});
</script>


<style>
@media screen and (orientation: portrait) {
  .modal-wrapper {
    width: 60%;
    height: auto;
  }
  .modal-content .slot-content {
    width: 100%;
    margin: auto;
    display: block;
  }
  .modal-footer-text {
    height: 2rem;
    font-size: 0.6rem;
  }
}
@media screen and (orientation: landscape) {
  .modal-wrapper {
    width: auto;
    height: 70%;
  }
  .modal-content .slot-content {
    height: 100%;
    margin: auto;
    display: block;
  }
  .modal-footer-text {
    font-size: 0.6rem;
  }
}
.modal-wrapper {
  position: fixed;
  z-index: 999;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  background: linear-gradient(to bottom, #f9c983, #edab4a);
  padding: 0.8rem;
  border-radius: 1.4rem;
  box-shadow: 0 10px 14px -7px #000;
}
.modal-content {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: #fbead1;
  box-shadow: 0 2px 8px rgb(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-footer-text {
  text-align: center;
  height: 1.8rem;
  color: var(--text-color);
  margin-top: 0.2rem;
}

.modal-close-btn {
  /* position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  font-size: 1.7rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid #fff;
  text-align: center;
  color: #fff;
  cursor: pointer;
  user-select: none; */
  position: absolute;
  bottom: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.7rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  font-weight: bold;
  border-radius: 50%;
  border: 1px solid #fff;
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  user-select: none;
}
.modal-close-btn:hover,
.modal-close-btn:active {
  background-color: rgba(255, 255, 255, 0.5);
}

.modal-enter-active {
  transform-origin: center;
  animation: bounce 0.2s linear;
  transform-origin: left;
}
.modal-leave-active {
  transform-origin: center;
  animation: bounce 0.2s linear reverse;
  transform-origin: left;
}
@keyframes bounce {
  0% {
    transform: scale(0) translateX(-50%);
  }
  80% {
    transform: scale(1.2) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}
</style>